<script>
import 'flag-icons/css/flag-icons.min.css';

export default {
    props: {
        plans: {
            type: Array,
            default: []
        },
        frequencies: {
            type: Array,
            default: []
        },
        subscribed: {
            type: Boolean,
            default: false
        },
        transactionCount: {
            type: String,
            default: '0'
        },
        hasExpiredTrial: {
            type: Boolean,
            default: false
        },
        onTrial: {
            type: Boolean,
            default: false
        },
        trialEndsAt: {
            type: String,
            default: ''
        },
        activePlan: {
            type: Object,
            default: {}
        },
        canLogin: Boolean,
        canRegister: Boolean,
    },
    beforeMount() {
        //add class to document body
        document.body.classList.remove('bg-body-light', 'dark:bg-zinc-950');
        document.body.classList.add('bg-zinc-950');
    },
}

</script>

<template>
    <Head>
        <title>Connect your bank to your budget</title>
        <meta name="description" content="Easily connect your bank accounts to YNAB. Synci is integrated with 2300+ banks across 31 European countries. Claim your free 7-day trial.">
    </Head>

    <div class="min-h-screen overflow-hidden bg-zinc-950">
        <!-- Header -->
        <Navbar />

        <main class="grow">
            <!-- Hero section -->
            <div class="relative isolate overflow-hidden">
                <div class="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
                    <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-blue-500 via-emerald-500 to-emerald-500 opacity-40" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
                </div>
                <div class="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:px-8 lg:pt-32">
                    <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                        <div class="mt-24 sm:mt-32 lg:mt-16">

                        </div>
                        <h1 class="mt-10 h2 text-zinc-100">Connect your bank to your budget</h1>
                        <p class="mt-6 text-lg leading-8 text-zinc-300">Easily connect your bank accounts to YNAB. Synci is integrated with 2300+ banks across 31 European countries.</p>
                        <div class="mt-10 mb-20 flex items-center gap-x-6">
                            <Link :href="$page.props.auth.user ? route('dashboard') : route('register')">
                                <PrimaryButton force-dark-mode>{{ $page.props.auth.user ? 'Go to Dashboard' : 'Claim your free 7-day trial' }}</PrimaryButton>
                            </Link>
                        </div>

                        <Link :href="route('home.features.supported-banks')">
                            <div class="isolate mb-4 flex items-center space-x-2 overflow-hidden opacity-90">
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" :class="'fi-' + $page.props.currentCountry"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'no'" :class="'fi-no'"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'se'" :class="'fi-se'"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'dk'" :class="'fi-dk'"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'fi'" :class="'fi-fi'"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'it'" :class="'fi-it'"></span>
                                <span class="relative fi !bg-cover !w-6 !h-6 !aspect-square rounded-full" v-if="$page.props.currentCountry !== 'fr'" :class="'fi-fr'"></span>
                                <span class="text-zinc-100 text-center bg-zinc-100/10 flex justify-center items-center rounded-full h-8 px-3 font-semibold text-xs">+25 more</span>
                            </div>
                            <span class="text-sm font-medium leading-6 text-zinc-100">See all supported banks <span aria-hidden="true">→</span></span>
                        </Link>


                    </div>
                    <div class="mx-auto mt-16 hidden sm:flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none :bg-black rounded-2xl w-[35rem]">
                            <img draggable="false" v-lazy="'/assets/screenshots/sources-dark2.webp'" alt="App screenshot" width="1512" height="1786" class="w-full h-full rounded-2xl ring-1 ring-white/[10%]" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Logo cloud -->
            <div class="mx-auto my-16 max-w-7xl px-6 sm:my-0 lg:px-8 text-center rounded-xl">
                <div class="mx-auto mt-5 grid max-w-lg items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none grid-cols-1">
                    <img class="col-span-2 block max-h-16 mx-auto w-full object-contain lg:col-span-1" v-lazy="'/assets/logos/works_with_ynab.svg'" alt="YNAB" width="196" height="78" />
                </div>
                <div class="mt-6 flex justify-center">
                    <p class="relative rounded-full bg-zinc-100/10 px-4 py-1.5 text-sm leading-6 text-zinc-300">
                        <span class="">Over {{ transactionCount }} transactions processed since May 2023</span>
                        <a target="_blank" href="https://www.trustpilot.com/review/synci.io" class="font-semibold hidden text-white"><span class="absolute inset-0" aria-hidden="true" /> Check us out on Trustpilot <span aria-hidden="true">&rarr;</span></a>
                    </p>
                </div>
            </div>


                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="pt-16 md:pt-32">

                        <!-- Section header -->
                        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                            <h2 class="h2 bg-clip-text text-transparent bg-gradient-to-r from-zinc-200/60 via-zinc-200 to-zinc-200/60 pb-4">Focus on budgeting.</h2>
                            <p class="text-lg text-zinc-400">Stay focused on managing your budget, and let Synci handle the data entry.</p>
                        </div>

                        <!-- Highlighted boxes -->
                        <div class="relative pb-4">
                            <!-- Grid -->
                            <Highlighter class="grid md:grid-cols-12 gap-6 group">
                                <!-- Box #1 -->
                                <div class="md:col-span-12" data-aos="fade-down">
                                    <HighlighterItem>
                                        <div class="relative h-full bg-card-dark rounded-[inherit] z-20 overflow-hidden">
                                            <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                                                <!-- Blurred shape -->
                                                <div class="absolute block right-0 top-0 blur-2xl" aria-hidden="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="342" height="393">
                                                        <defs>
                                                            <linearGradient id="bs-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                                                <stop offset="0%" stop-color="#00966A" />
                                                                <stop offset="100%" stop-color="#00966A" stop-opacity="0" />
                                                            </linearGradient>
                                                        </defs>
                                                        <path fill="url(#bs-a)" fill-rule="evenodd" d="m104 .827 461 369-284 58z" transform="translate(0 -112.827)" opacity=".7" />
                                                    </svg>
                                                </div>
                                                <!-- Radial gradient -->
                                                <div class="absolute flex items-center justify-center bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square" aria-hidden="true">
                                                    <div class="absolute inset-0 translate-z-0 bg-zinc-900 rounded-full blur-[120px] opacity-70"></div>
                                                    <div class="absolute w-1/4 h-1/4 translate-z-0 bg-zinc-700 rounded-full blur-[40px]"></div>
                                                </div>


                                                <!-- Text -->
                                                <div class="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8 md:pr-0">
                                                    <div class="mb-5">
                                                        <div>
                                                            <h3 class="inline-flex text-xl font-bold text-zinc-100 pb-1">Stay in sync</h3>
                                                            <p class="text-zinc-400">Synci is built for users who prefer to budget without manual data entry. Stay up to date with your bank accounts, without doing any of the heavy lifting.</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link :href="route('home.features.supported-banks')">
                                                          <SecondaryButton force-dark-mode>
                                                            Supported Banks <span class="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                                          </SecondaryButton>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <!-- Image -->
                                                <div class="relative w-full h-64 md:h-auto overflow-hidden">
                                                    <img class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:relative md:left-0 md:translate-x-0" draggable="false" v-lazy="'/assets/feature1.svg'" width="504" height="400" alt="Feature 01">
                                                </div>
                                            </div>
                                        </div>
                                    </HighlighterItem>
                                </div>
                                <!-- Box #2 -->
                                <div class="md:col-span-7" data-aos="fade-down">
                                    <HighlighterItem>
                                        <div class="relative h-full bg-card-dark rounded-[inherit] z-20 overflow-hidden">
                                            <div class="flex flex-col">
                                                <!-- Radial gradient -->
                                                <div class="absolute flex bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                                                    <div class="absolute inset-0 translate-z-0 bg-zinc-900/80 rounded-full blur-[80px]"></div>
                                                </div>
                                                <!-- Text -->
                                                <div class="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8 md:pr-0">
                                                    <div>
                                                        <h3 class="inline-flex text-xl font-bold text-zinc-100 pb-1">Full control</h3>
                                                        <p class="text-zinc-400">We strive to offer a balance of convenience, security and privacy. Use logs to see how your data flows from your bank to your budget.</p>
                                                    </div>
                                                </div>
                                                <!-- Image -->
                                                <div class="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                    <img class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0" v-lazy="'/assets/logs.svg'" width="490" height="210" draggable="false" alt="Feature 02">
                                                </div>
                                            </div>
                                        </div>
                                    </HighlighterItem>
                                </div>
                                <!-- Box #3 -->
                                <div class="md:col-span-5" data-aos="fade-down">
                                    <HighlighterItem>
                                        <div class="relative  h-full bg-card-dark rounded-[inherit] z-20 overflow-hidden">
                                            <div class="flex flex-col">
                                                <!-- Radial gradient -->
                                                <div class="absolute flex bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                                                    <div class="absolute inset-0 translate-z-0 bg-zinc-900/80 rounded-full blur-[80px]"></div>
                                                </div>
                                                <!-- Text -->
                                                <div class="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8 md:pr-0">
                                                    <div>
                                                        <h3 class="inline-flex text-xl font-bold text-zinc-100 pb-1">Easy setup</h3>
                                                        <p class="text-zinc-400">Synci makes it easy to connect your banks. Simply select which bank you'd like to use, and you will be redirected to your banks login page.</p>
                                                    </div>
                                                </div>
                                                <!-- Image -->
                                                <div class="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                    <img class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0" draggable="false" v-lazy="'/assets/banks.svg'" width="490" height="210" alt="Feature 03">
                                                </div>
                                            </div>
                                        </div>
                                    </HighlighterItem>
                                </div>
                            </Highlighter>
                        </div>

                    </div>
                </div>

            <TestimonialsCarousel />

            <Features03 />


            <!-- Features with screenshot -->
            <FeaturesWithScreenshot />

            <!-- Pricing section -->
            <section id="pricing">
                <div class="relative mx-auto max-w-7xl px-6 lg:flex lg:px-8 pt-24 mb-24">
                    <!-- Radial gradient -->
                    <div class="absolute inset-0 overflow-hidden pointer-events-none -z-10" aria-hidden="true">
                        <div class="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-1/3 aspect-square">
                            <div class="absolute inset-0 translate-z-0 bg-emerald-500 rounded-full blur-[120px] opacity-25"></div>
                        </div>
                    </div>

                    <div class="max-md:hidden absolute bottom-0 -mb-20 left-2/3 -translate-x-1/2 blur-2xl opacity-70 pointer-events-none" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                            <defs>
                                <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                    <stop offset="0%" stop-color="#00966A" />
                                    <stop offset="100%" stop-color="#00966A" stop-opacity="0" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#bs5-a)" fill-rule="evenodd" d="m661 736 461 369-284 58z" transform="matrix(1 0 0 -1 -661 1163)" />
                        </svg>
                    </div>
                    <div class="w-full z-10">
                <PricingDarkOnly :plans="plans" :with-padding="false"
                                 :has-expired-trial="hasExpiredTrial" :subscribed="subscribed" :on-trial="onTrial" :trial-ends-at="trialEndsAt" />
                    </div>
                </div>
            </section>

            <Testimonials />

            <!-- CTA section -->
            <div class="relative isolate px-6 py-32 lg:px-8">
                <div class="absolute inset-x-0 left-0 -top-10 -z-10 flex transform-gpu rotate-180 justify-center overflow-hidden blur-3xl">
                    <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-blue-500 via-emerald-500 to-emerald-500 opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />

                </div>
                <div class="mx-auto max-w-2xl text-center">
                  <h2 class="h2 bg-clip-text text-transparent bg-gradient-to-r from-zinc-200/60 via-zinc-200 to-zinc-200/60 pb-4">Get connected.</h2>
                  <p class="text-lg text-zinc-400">Tired of the tedious task of manually importing your bank transactions into You Need a Budget? That's what we aim to solve! Just sign up for the free 7-day trial, connect your bank account, and let Synci do the rest.</p>
                  <div class="mt-10 flex items-center justify-center gap-x-6">
                    <PrimaryButton force-dark-mode :href="route('register')">Get started</PrimaryButton>
                    </div>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <Footer />
    </div>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import Navbar from "./Components/Navbar.vue";
import Footer from "./Components/Footer.vue";
import FeaturesWithScreenshot from "./Components/FeaturesWithScreenshot.vue";
import HighlighterItem from "./Landing/partials/HighlighterItem.vue";
import Highlighter from "./Landing/partials/Highlighter.vue";
import PrimaryButton from "../Components/PrimaryButton.vue";
import SecondaryButton from "../Components/SecondaryButton.vue";
import TestimonialsCarousel from "./Landing/partials/TestimonialsCarousel.vue";
import Features03 from "./Landing/partials/Features03.vue";
import PricingDarkOnly from "./Components/PricingDarkOnly.vue";
import Testimonials from "./Landing/partials/Testimonials.vue";
</script>
